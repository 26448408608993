
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,800;1,700;1,800&family=Poppins&family=Quicksand:wght@300;400;500;600;700&display=swap');
body{
    background: linear-gradient(90deg, #FCF7F7 0%, #DECECE 100%);

}
*{
    scroll-behavior: smooth;
}
.bg-colors{


background: linear-gradient(90deg, #FADB06 0%, #3FC2F6 50%, #ED0F58 100%);

}

.w-fill-av {
    width: -webkit-fill-available !important;
}
.h-fill-av {
    height: -webkit-fill-available !important;
}
.font-josef{
    font-family: 'Josefin Sans', sans-serif;
}

.font-sand{
    font-family: 'Quicksand', sans-serif;
}
.font-ops{
    font-family: 'Open Sans', sans-serif;
}
.font-monta{
    font-family: 'Montserrat', sans-serif;
}
.font-pop{
    font-family: 'Poppins', sans-serif;
}

.bg-primary-center{

background: linear-gradient(180deg, rgba(255, 173, 200, 0) 43.74%, #FFADC8 43.75%, #FFADC8 52.6%, rgba(255, 173, 200, 0) 52.61%);

}
.cut{
    background:
    linear-gradient(to top left, #F180A3 50%, transparent 50.5%) no-repeat top,
    /* bottom part */
    linear-gradient(0deg, #F180A3, #F180A3) no-repeat bottom;
  /* top portion */

  background-size: 100% 7em, 100% calc(100% - 7em)
}
.bg-pic{



        background: linear-gradient(0deg, rgba(252, 72, 141, 0.25), rgba(224, 39, 39, 0.25)), url("../public/assets/bg1.png");
        mix-blend-mode: soft-light;
}
.bg-colors-box{


background: linear-gradient(180deg, #ED0F58 0%, #DE1E65 93.6%, #59A6DE 93.98%, #4CB4EA 96.49%, #F9DB08 96.88%);

}


.slider-card{
    width: 320px;
    height: 300px;
    background: white;
    border-radius: 10px;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 12%);
    cursor: pointer;
}
